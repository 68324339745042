import type { SubscriptionInclude } from '@/interfaces';
import type { Organization } from '@prisma/client';
import type { Stripe } from 'stripe';

export const useOrganizationStore = defineStore('organization', () => {
  
  const { data: invoices, refresh: refreshInvoices} = useFetch<Stripe.Invoice[] | null>(`/api/stripe/invoices`, {
      method: 'GET',
      retry: false,
    });
  
  const { data: subscription , refresh: refreshSubscription}=  useFetch<SubscriptionInclude >(`/api/subscriptions`, {
      method: 'GET',
      retry: false,
    });

  const { data: organization, refresh: refreshOrganization } =  useFetch<Organization>(`/api/organizations/`, {
      retry: false,
    });

 
  async function cancelSubscription(token: string) {
    await $fetch<SubscriptionInclude | null>(
      `/api/stripe/cancel-subscription`,
      {
        method: 'POST',
        headers: { 'X-Recaptcha-Token': token },
        retry: false,
      }
    );
    await refreshSubscription()
  }


  async function bookCreditsReserved() {
    await $fetch<SubscriptionInclude | null>(
      `/api/subscriptions/book-credits-reserved`,
      {
        method: 'POST',
        retry: false,
      }
    );
    await refreshSubscription()
  }

  async function releaseCreditsReserved() {
    await $fetch<SubscriptionInclude | null>(
      `/api/subscriptions/release-credits-reserved`,
      {
        method: 'POST',
        retry: false,
      }
    );
    await refreshSubscription()
  }

  async function deleteOrganization() {
    await $fetch<SubscriptionInclude | null>(
      `/api/organizations/`,
      {
        method: 'DELETE',
        retry: false,
      }
    );
    await refreshSubscription()
  }

  function clear() {
    organization.value = null;
    invoices.value = null;
    subscription.value = null;
  }

  return {
    invoices,
    organization,
    subscription,
    bookCreditsReserved,
    cancelSubscription,
    deleteOrganization,
    clear,
    refreshSubscription,
    refreshOrganization,
    refreshInvoices,
    
    releaseCreditsReserved,
  };
});
